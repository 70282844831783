/*
 This is the base element for our custom elements
 We have added another life cycle callback since connectedCallback can be called before
 children are rendered. To keep this class simple we must make sure to call `isRendered`
 from within the connectedCallback method in every class that extends this class.
 */
class AIDElement extends HTMLElement {
    isRendered(isParsed) {
        let current = this;
        let parsed = isParsed || false;

        if (!parsed) {
            if (this.nextSibling) {
                parsed = true;
            } else {
                while (current.parentNode || current === document.documentElement) {
                    current = current.parentNode;
                    if (current.nextSibling) {
                        parsed = true;
                        break;
                    }
                }
            }
        }
        if (!parsed && document.readyState === 'loading') {
            if (!this.observer) {
                this.observer = new MutationObserver(() => this.isRendered());
                this.observer.observe(this.parentNode, { childList: true, subtree: true });
                document.addEventListener('DOMContentLoaded', () => this.isRendered(true));
            }
            return;
        }

        if (this.observer) {
            this.observer.disconnect();
        }
        this.childrenParsedCallback();
    }

    /**
     * NB: This may be called multiple times
     */
    childrenParsedCallback() {}
}

export { AIDElement };
