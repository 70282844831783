export const preventSecondSubmit = (form, submitButton) => {
    function _preventSecondSubmit() {
        form.addEventListener('submit', e => e.preventDefault());
        form.removeEventListener('submit', _preventSecondSubmit);
        if (submitButton) {
            submitButton.disabled = true;
        }
    }
    form.addEventListener('submit', _preventSecondSubmit);
    window.addEventListener('releaseDisabled', () => {
        submitButton.disabled = false;
    });
};
