/* global document, customElements, HTMLElement */

import { AIDElement } from '../../common/element';
import { preventSecondSubmit } from '../../lib/form-helpers';

class AidButton extends AIDElement {
    connectedCallback() {
        this.isRendered();
    }

    addRipple(e) {
        const width = this.offsetWidth;
        const pos = this.getBoundingClientRect();
        const rippleElement = document.createElement('span');
        rippleElement.classList.add('ripple');
        const left = e.clientX - pos.left;
        const top = e.clientY - pos.top;
        const size = Math.max(width - left, left) * 2;
        rippleElement.style.top = `${top - size / 2}px`;
        rippleElement.style.left = `${left - size / 2}px`;
        rippleElement.style.width = `${size}px`;
        rippleElement.style.height = `${size}px`;
        this.appendChild(rippleElement);

        setTimeout(() => this.removeChild(rippleElement), 1000);
    }

    constructor() {
        super();
        if (!this.classList.contains('no-ripple')) {
            this.addEventListener('mousedown', e => this.addRipple(e));
        }
    }

    childrenParsedCallback() {
        const submitaction = this.getAttribute('submitaction');
        const target = this.getAttribute('target');
        const content = this.getAttribute('content');

        const button = this.querySelector('button');

        if (button && button.type === 'submit') {
            const parentForm = this.closest('form');
            if (parentForm) {
                preventSecondSubmit(parentForm, button);
            }
        }

        if (submitaction && button && button.type === 'submit') {
            button.addEventListener('click', () => {
                this.insertAdjacentHTML(
                    'afterend',
                    `<input name="submitaction" type="hidden" value="${submitaction}" />`
                );
            });
        } else if (button && target && content) {
            button.addEventListener('click', () => {
                const evt = new CustomEvent(`aid:${target}`, { detail: { targetId: content, trigger: button }});
                window.dispatchEvent(evt);
            });
        }
    }
}

if (!customElements.get('aid-button')) {
    customElements.define('aid-button', AidButton);
}
